import { createContext, FC, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react'

interface PayrollPayContextState {
    payrollUpdated: number
    setPayrollUpdated: Dispatch<SetStateAction<number>>
}

const PayrollPayContext = createContext<PayrollPayContextState | undefined>(undefined)
PayrollPayContext.displayName = 'PayrollPayContext'

const PayrollPayProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [payrollUpdated, setPayrollUpdated] = useState<number>(0)

    return (
        <PayrollPayContext.Provider value={{ payrollUpdated, setPayrollUpdated }}>
            {children}
        </PayrollPayContext.Provider>
    )
}

const usePayrollPay = (): PayrollPayContextState => {
    const context = useContext(PayrollPayContext)
    if (!context) {
        throw new Error('usePayrollPay must be used within a PayrollPayProvider')
    }

    return context
}

export { PayrollPayProvider, usePayrollPay }