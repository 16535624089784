
export const endpoints = {
    loginGoogleAuth: '/api/identity/auth/google',
    getUserInfo: '/api/identity/getUserInfo',
    refreshToken: '/api/identity/refreshcookies',
    logOut: '/api/identity/logout',
    getFarms: '/api/farm/getFarms?tenantId=',
    getFarmJobTypes: '/api/farm/getFarmJobTypes',
    getEmployeeJobTypes: '/api/journal/getEmployeeJobTypes?tenantId=',
    getEmployees: '/api/employee/getEmployees?tenantId=',
    createJournalDailyWork: '/api/journal/createJournalDailyWork',
    getProducts: '/api/product/getProducts?tenantId=',
    getProductMeasures: '/api/product/getProductMeasures',
    createProduct: '/api/product/createProduct',
    editProduct: '/api/product/editProduct',
    deleteProduct: '/api/product/deleteProduct?id={productId}&deletedByUserEmail={deletedByUserEmail}',
    getJournalDailyWorks: '/api/journal/getJournalDailyWorks',
    getJournalDailyWork: '/api/journal/getJournalDailyWork?journalDailyWorkId=',
    getPayrollDetails: '/api/payroll/getPayrollDetails',
    editJournalDailyWork: '/api/journal/editJournalDailyWork',
    deleteJournalDailyWork: '/api/journal/deleteJournalDailyWork?id={journalDailyWorkId}&deletedByUserEmail={deletedByUserEmail}',
    getTenants: '/api/identity/getTenants',
    getPaymentTypes: '/api/employee/getPaymentTypes',
    getPayrollsMaster: '/api/payroll/getPayrollsMaster?payrollStatus=',
    getInProgressPayroll: '/api/payroll/getInProgressPayroll',
    createPayroll: '/api/payroll/createPayroll',
    deletePayroll: '/api/payroll/deletePayroll?id={payrollId}&deletedByUserId={deletedByUserId}',
    approvePayroll: '/api/payroll/approvePayroll',
    addDetailToPayroll: '/api/payroll/addDetailToPayroll',
    removeDetailFromPayroll: '/api/payroll/removeDetailFromPayroll',
    getPayrollMasterForJournalDailyWork: '/api/payroll/getPayrollMasterForJournalDailyWork?journalDailyWorkId=',
}