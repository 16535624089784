import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'
import { CssBaseline } from '@mui/material'
import { HelmetProvider } from 'react-helmet-async'

import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser"
import { msalConfig } from "./authConfig"

const msalInstance = new PublicClientApplication(msalConfig)

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload
  ) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
  }
})


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <CssBaseline />
        <Routes>
          <Route path='/*' element={<App pca={msalInstance} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
  // </React.StrictMode>
)
