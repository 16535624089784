import { FC, Fragment, useEffect, useState } from "react";
import PayrollCalculateView from "./PayrollCalculateView";
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import { PayrollStatus } from "../../types/Payroll"
import PayrollPayView from "./PayrollPayView"
import ReportSkeletons from './ReportSkeletons'
import { PayrollPayProvider } from "../../context/PayrollPayProvider";

const MIN_LOADING_TIME = 500
type PayrollMasterStatusResponse = {
    id: number
    payrollDate: Date
    payrollStatus: PayrollStatus
}

const PayrollCalculateAndPayView: FC = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { showError } = useSnackbar()
    const [payrollInProgess, setPayrollInProgress] = useState<PayrollMasterStatusResponse | null>(null)

    const getPayrolls = async (controller: AbortController) => {
        setIsLoading(true)
        try {
          const fetchStart = Date.now()
    
          const responsePromise = axiosPrivate.get(endpoints.getInProgressPayroll, {
            signal: controller.signal,
          })
    
          const [response] = await Promise.all([
            responsePromise,
            new Promise((resolve) =>
              setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
            ), // Ensure a minimum loading time
          ])          

          setPayrollInProgress(response.data || null)
          setIsLoading(false)
        } catch (err: any) {
          const error = err as AxiosError
          if (error.name !== 'CanceledError') {
            setIsLoading(false)
            showError('Error al consultar la planilla en progreso de pago')
          }
        }        
      }
    
      useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
    
        if (isMounted) {
          getPayrolls(controller)
        }
    
        return () => {
          isMounted = false
          controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    return (
      <Fragment>
        {isLoading ? (
          <ReportSkeletons count={5} />
        ) : payrollInProgess !== null &&
          payrollInProgess?.payrollStatus !== PayrollStatus.Paid ? (
          <PayrollPayProvider>
            <PayrollPayView payrollId={payrollInProgess.id} payrollDate={payrollInProgess.payrollDate}/>
          </PayrollPayProvider>
        ) : (
          <PayrollCalculateView />
        )}
      </Fragment>
    )    
}

export default PayrollCalculateAndPayView