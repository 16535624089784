import { Employee } from "./Employee"
import { Farm } from "./Farm"
import { JournalDailyWorkProduct } from "./Product"

export type FarmJobType = {
    id: number
    name: string
  }

  export type EmployeeJobType = {
    id: number
    name: string
    jobPrice: EmployeeJobTypePrice
  }

  export type EmployeeJobTypePrice = {
    id: number
    price: number
  }

  export type JournalDailyWorkDetail = {
    id: number
    employee: Employee
    employeeJobType: EmployeeJobType | null
    jobQuantity: number
    jobPrice: number
    paid: boolean
  }


  export enum JournalDailyWorkPaymentStatus {
    PendingPayment = 1,
    InProgressPayment = 2,
    Paid = 3,
    All = 4
}

export function getJournalDailyWorkPaymentStatusLabel(status: JournalDailyWorkPaymentStatus): string {
  switch (status) {
    case JournalDailyWorkPaymentStatus.PendingPayment:
      return "Pendiente de pago";
    case JournalDailyWorkPaymentStatus.Paid:
      return "Pagado";
    case JournalDailyWorkPaymentStatus.All:
      return "Todo";
    default:
      return "Desconocido";
  }
}

export type JournalDailyWork = {
  id: number
  createdDate: string
  modifiedDate: string
  createdByUserEmail: string
  modifiedByUserEmail: string
  farmId: number
  journalDailyWorkDate: string
  description: string
  tenantId: number
  canEdit: boolean
  canDelete: boolean
  farm: Farm
  journalDailyWorkDetails: Array<{
    id: number
    journalDailyWorkId: number
    employee: Employee
    employeeJobType: EmployeeJobType
    jobQuantity: number
    jobPrice: number
    paid: boolean
  }>
  journalDailyWorkFarmJobTypes: Array<{
    id: number
    journalDailyWorkId: number
    farmJobType: FarmJobType
  }>
  journalDailyWorkProducts: JournalDailyWorkProduct[]
}
  



