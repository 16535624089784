import { Routes, Route } from 'react-router-dom'
import Layout from '../layouts/Layout'
import LoginPage from '../pages/Login'
import Unauthorized from '../components/Auth/Unauthorized'
import PersistLogin from '../components/Auth/PersistLogin'
import Missing from '../components/Missing/Missing'
import RequireAuth from '../components/Auth/RequireAuth'
import Home from '../pages/Home'
import LogOut from '../components/LogOut/LogOut'
import ProductInventory from '../pages/ProductInventory'
import CreateProductInventory from '../pages/CreateProductInventory'
import EditProductInventory from '../pages/EditProductInventory'
import CreateDailyWorkReport from '../pages/CreateDailyWorkReport'
import DailyWorkReport from '../pages/DailyWorkReport'
import EditDailyWorkReport from '../pages/EditDailyWorkReport'
import CalculatePayroll from '../pages/CalculatePayroll'
import Payroll from '../pages/Payroll'
import PayrollDetails from '../pages/PayrollDetails'
import DailyWorkReportDetails from '../pages/DailyWorkReportDetails'

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* public routes */}
        <Route path='login' element={<LoginPage />} />
        <Route path='unauthorized' element={<Unauthorized />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='/' element={<Home />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='reporte-trabajo' element={<DailyWorkReport />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='reporte-trabajo/detalles/:journalDailyWorkId' element={<DailyWorkReportDetails />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='reporte-trabajo/agregar'
              element={<CreateDailyWorkReport />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='reporte-trabajo/editar/:reportId'
              element={<EditDailyWorkReport />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla/calcular' element={<CalculatePayroll />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla/detalles/:payrollMasterId' element={<PayrollDetails />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla' element={<Payroll />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='inventario-productos' element={<ProductInventory />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='inventario-productos/agregar'
              element={<CreateProductInventory />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='inventario-productos/editar/:productId'
              element={<EditProductInventory />}
            />
          </Route>

          <Route path='/logout' element={<LogOut />} />
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default Router
