import { FC, ReactElement, ReactNode, useState } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FarmInfo from './FarmInfo'
import EmployeeSelection from './EmployeeSelection'
import EmployeeWorkDetails from './EmployeeWorkDetails'
import { formatFullDateWithDay } from '../../utils/format-time'
import ValidateWorkDetails from './ValidateWorkDetails'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import Iconify from '../../components/iconify'

interface DailyWorkReportStepsProps {
  activeStep: number
  setActiveStep: (value: number | ((prevActiveStep: number) => number)) => void
  lastStepButtonLabel: string
  successMessage: string
  onSubmit: () => void  
}

interface StepItem {
  label: string
  component: ReactElement
  optional?: ReactNode
}

const DailyWorkReportSteps: FC<DailyWorkReportStepsProps> = ({  
  activeStep,
  setActiveStep,
  lastStepButtonLabel,
  successMessage,
  onSubmit,  
}) => {
  const [validateFarmRequiredValues, setValidateFarmRequiredValues] = useState<boolean>(false)
  const [validateEmployeeSelection, setValidateEmployeeSelection] = useState<boolean>(false)
  const [validateEmployeeWorkDetails, setValidateEmployeeWorkDetails] = useState<boolean>(false)
  const {
    selectedFarm,
    setSelectedFarm,
    selectedFarmJobTypes,
    setSelectedFarmJobTypes,
    selectedDate,
    setSelectedDate,
    setObservations,
    selectedProducts,
    setSelectedProducts,
    journalDailyWorkProducts,
    setJournalDailyWorkProducts,
    selectedEmployees,
    setSelectedEmployees,
    journalDailyWorkDetails,
    setJournalDailyWorkDetails,
  } = useDailyWorkReport()
  const navigate = useNavigate()
  const location = useLocation()

  const steps: StepItem[] = [
    {
      label: 'Dónde y cuándo',
      component: (
        <FarmInfo        
          validateFarmRequiredValues={validateFarmRequiredValues}
        />
      ),
      optional:
        selectedFarm && selectedDate ? (
          <Typography variant='caption'>
            {formatFullDateWithDay(selectedDate)} - {selectedFarm.name}
          </Typography>
        ) : null,
    },
    {
      label: 'Quiénes trabajaron ese día',
      component: (
        <EmployeeSelection          
          validateEmployeeSelection={validateEmployeeSelection}
        />
      ),
    },
    {
      label: 'En qué trabajó cada uno',
      component: (
        <EmployeeWorkDetails         
          validateEmployeeWorkDetails={validateEmployeeWorkDetails}
        />
      ),
    },
    {
      label: 'Revisar y enviar',
      component: (
        <ValidateWorkDetails/>
      ),
    },
  ]
  
  const isStepValid = () => {
    switch (activeStep) {
      case 0: // FarmInfo
        setValidateFarmRequiredValues(true)
        return (
          selectedFarm &&
          selectedDate &&
          selectedFarmJobTypes.length > 0 &&
          (selectedProducts.length > 0
            ? !journalDailyWorkProducts.some(
                (detail) => detail.productQuantity === 0
              )
            : true)
        )
      case 1: // EmployeeSelection
        setValidateEmployeeSelection(true)
        validateEmployeeWorkDetails && setValidateEmployeeWorkDetails(false)
        return selectedEmployees.length > 0
      case 2: // EmployeeWorkDetails
        setValidateEmployeeWorkDetails(true)
        return (
          !journalDailyWorkDetails.some(
            (detail) =>
              detail.employeeJobType === null || detail.jobQuantity === 0
          ) && selectedEmployees.length > 0
        )
      default:
        return true
    }
  }

  const handleNext = (action: 'continue' | 'submit') => {
    if (!isStepValid()) {
      return
    }
    if (action === 'submit') {
      onSubmit()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const resetValues = () => {
    setValidateFarmRequiredValues(false)
    setValidateEmployeeSelection(false)
    setValidateEmployeeWorkDetails(false)
    setSelectedDate(null)
    setObservations(undefined)
    setSelectedFarm(null)
    setSelectedFarmJobTypes([])
    setJournalDailyWorkDetails([])
    setSelectedEmployees([])
    setSelectedProducts([])
    setJournalDailyWorkProducts([])
  }
  const handleReset = () => {
    setActiveStep(0)
    resetValues()
    if (!location.pathname.includes('/reporte-trabajo/agregar')) {      
      navigate('/reporte-trabajo/agregar');
    }
  }  

  return (
    <Card>
      <CardHeader title='Complete la siguiente información' />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={10}>
            <Stepper
              activeStep={activeStep}
              orientation='vertical'
              sx={{ ml: 1, mt: 1 }}
            >
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel optional={step.optional}>{step.label}</StepLabel>
                  <StepContent>
                    {step.component}

                    <Box sx={{ mb: 1 }}>
                      <Button
                        variant='contained'
                        color='inherit'
                        onClick={() =>
                          handleNext(
                            index === steps.length - 1 ? 'submit' : 'continue'
                          )
                        }
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1
                          ? lastStepButtonLabel
                          : 'Siguiente'}
                      </Button>
                      {index !== 0 && (
                        <Button
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                          color='inherit'
                        >
                          Anterior
                        </Button>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography sx={{ mb: 3 }}>{successMessage}</Typography>
                <Button
                  sx={{ mt: 1, mr: 1 }}
                  onClick={() => {
                    navigate(-1)
                  }}
                  variant='contained'
                  color='inherit'
                  startIcon={<Iconify icon='eva:arrow-back-fill' />}
                >
                  Regresar
                </Button>
                <Button
                  onClick={handleReset}
                  sx={{ mt: 1, mr: 1 }}
                  color='primary'
                  variant='contained'
                >
                  Enviar un nuevo reporte
                </Button>                
              </Paper>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DailyWorkReportSteps
