import { FC, useState } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { endpoints } from '../../api/constants'
import { axiosPrivate, ApiError } from '../../api/axios'
import { ProductMeasure, ProductInventory } from '../../types/Product'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import { useAuth } from '../../context/AuthProvider'
import Loading from '../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'


type EditProductRequest = {
  id: number
  updatedByUserEmail: string
  name: string
  unitPrice: number
  quantity: number
  description: string
}

interface EditProductInventoryViewProps {
  currentProduct: ProductInventory
}

const EditProductInventoryView: FC<EditProductInventoryViewProps> = ({
  currentProduct
}) => {
  const { showSuccess, showError } = useSnackbar()
  const { auth } = useAuth()  
  const [name, setName] = useState<string>(currentProduct.name)
  const [unitPrice, setUnitPrice] = useState<number>(currentProduct.unitPrice)
  const [quantity, setQuantity] = useState<number>(currentProduct.quantity)
  const [description, setDescription] = useState<string>(currentProduct.description)
  const productMeasure: ProductMeasure = currentProduct.productMeasure
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
 
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    const productInventory: EditProductRequest = {
      id: currentProduct.id,
      name,
      unitPrice,
      quantity,
      description,
      updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
    }
   
    EditProduct(productInventory)
  }  

  const EditProduct = async (productInventory: EditProductRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendEditProductRequest = async () => {    
        try {
          const response = await axiosPrivate.put(
            endpoints.editProduct,
            productInventory,
            { signal: controller.signal }
          )         
    
          if (response.status === 200) {
            showSuccess('Producto actualizado correctamente!')            
            navigate('/inventario-productos')       
    
          }
        } catch (err: any) {
          const error = err as AxiosError<ApiError>
    
          if (error.name === 'CanceledError') {
            //console.log('The request has been canceled')
          } else {
            showError('Error actualizar el producto ' + error?.response?.data?.detail)
          }
        } finally {
          setIsLoading(false)
        }
      }

      SendEditProductRequest()
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  } 

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Typography variant='h4'>Agregar producto</Typography>
      </Stack>

      <form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {isLoading && <Loading centered={true} />}
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Nombre del Producto'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField                               
                  id='outlined-read-only-product-measure'
                  label='Medida del Producto'
                  variant='filled'
                  value={productMeasure.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />                
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Precio Unitario'
                  name='unitPrice'
                  type='number'
                  onChange={(e) => setUnitPrice(Number(e.target.value))}
                  required
                  value={unitPrice}
                  onFocus={handleFocus}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Cantidad'
                  name='quantity'
                  type='number'
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  required
                  value={quantity}
                  onFocus={handleFocus}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  label='Descripción'
                  name='description'
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  value={description}
                  multiline
                  maxRows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  )
}

export default EditProductInventoryView
