import { FC, useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, DialogActions, Button, IconButton, Tooltip, CircularProgress, Box } from '@mui/material'
import { EmployeeWorkDetailPayroll, JournalDailyWorkDetailPayroll } from '../../types/Payroll'
import { fNumber } from '../../utils/format-number'
import { fDateShort } from '../../utils/format-time'
import { getFullName } from '../../types/Employee'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { useAuth } from '../../context/AuthProvider'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import Loading from '../../components/Loading/Loading'
import { usePayrollPay } from '../../context/PayrollPayProvider'
import { useNavigate } from 'react-router-dom'

interface PayrollEmployeeWorkDetailsProps {
  open: boolean
  onClose: () => void
  employeeWorkDetails: EmployeeWorkDetailPayroll | null
  payrollId: number
  isDeleteAction: boolean  
}

type AddDetailToPayrollRequest = {
  updatedByUserEmail: string
  payrollMasterId: number
  journalDailyWorkDetailId: number
}

type RemoveDetailFromPayrollRequest = {
  updatedByUserEmail: string
  payrollMasterId: number
  payrollDetail: number
}

const PayrollEmployeeWorkDetails: FC<PayrollEmployeeWorkDetailsProps> = (
  { 
    open, 
    onClose, 
    employeeWorkDetails, 
    isDeleteAction, 
    payrollId
  }) => {
    const { auth } = useAuth() 
    const [isLoading, setIsLoading] = useState(false)
    const { showSuccess, showError } = useSnackbar()    
    const [journalDailyWorkDetails, setJournalDailyWorkDetails] = useState<JournalDailyWorkDetailPayroll[]>([])
    const [totalPayment, setTotalPayment] = useState<number>(0)
    const { setPayrollUpdated } = usePayrollPay()
    const navigate = useNavigate()

    const handleDateClick = (journalDailyWorkId: number) => {
      navigate(`/reporte-trabajo/detalles/${journalDailyWorkId}`, {
        state: { currentJournalDailyWorkId: journalDailyWorkId },
      })    
    }

    useEffect(() => {
      setJournalDailyWorkDetails(employeeWorkDetails?.journalDailyWorkDetails || [])
      setTotalPayment(employeeWorkDetails?.totalPayment || 0)
    }, [employeeWorkDetails])
  
  const handleDelete = (detail: JournalDailyWorkDetailPayroll) => {
    if (!isLoading) RemoveDetailFromPayroll(detail)
  }

  const handleAddToPayroll = (detail: JournalDailyWorkDetailPayroll) => {
    if (!isLoading) AddDetailToPayroll(detail)
  }

  const AddDetailToPayroll = async (
    detail: JournalDailyWorkDetailPayroll
  ) => {
    setIsLoading(true)
    const controller = new AbortController()
    const addDetailToPayrollRequest: AddDetailToPayrollRequest = {
      updatedByUserEmail: auth.user?.email || '',
      payrollMasterId: payrollId,
      journalDailyWorkDetailId: detail.id
    }

    const SendAddDetailToPayrollRequest = async () => {
      try {
        const response = await axiosPrivate.put(
          endpoints.addDetailToPayroll,
          addDetailToPayrollRequest,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Agregado a la planilla exitosamente!')
          setJournalDailyWorkDetails((prev) => prev.filter((d) => d.id !== addDetailToPayrollRequest.journalDailyWorkDetailId))
          setTotalPayment(totalPayment - detail.dayPayment)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error al actualizar la planilla ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendAddDetailToPayrollRequest()
  }

  const RemoveDetailFromPayroll = async (
    detail: JournalDailyWorkDetailPayroll
  ) => {
    setIsLoading(true)
    const controller = new AbortController()
    const removeDetailFromPayrollRequest: RemoveDetailFromPayrollRequest = {
      updatedByUserEmail: auth.user?.email || '',
      payrollMasterId: payrollId,
      payrollDetail: detail.payrollDetailId
    }

    const SendRemoveDetailFromPayrollRequest = async () => {
      try {
        const response = await axiosPrivate.put(
          endpoints.removeDetailFromPayroll,
          removeDetailFromPayrollRequest,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Removido de la planilla exitosamente!')
          setJournalDailyWorkDetails((prev) => prev.filter((d) => d.id !== detail.id))
          setTotalPayment(totalPayment - detail.dayPayment)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {          
          showError(
            'Error al actualizar la planilla ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendRemoveDetailFromPayrollRequest()
  }

  const handleOnClose = () => {
    setPayrollUpdated((prev) => prev + 1)
    onClose()
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth='md'
      fullWidth
      aria-labelledby='employee-work-details-dialog'
    >
      <DialogTitle id='employee-work-details-dialog'>
        {employeeWorkDetails
          ? getFullName(employeeWorkDetails.employee)
          : 'Detalles de la planilla'}
      </DialogTitle>
      {isLoading && <Loading centered={true} />}
      <DialogContent>
        <Box sx={{ position: 'relative' }}>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <TableContainer component={Paper}>
            <Table size='small' aria-label='Detalles del trabajo'>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Trabajo</TableCell>
                  <TableCell align='right'>Cantidad</TableCell>
                  <TableCell align='right'>Precio</TableCell>
                  <TableCell align='right'>SubTotal</TableCell>
                  <TableCell>Finca</TableCell>
                  <TableCell>Perfil</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {journalDailyWorkDetails.map((detail) => (
                  <TableRow key={detail.id}>
                    <TableCell>
                      <Button
                        onClick={() => {
                          handleDateClick(detail.journalDailyWork.id)
                        }}
                      >
                        {fDateShort(
                          detail.journalDailyWork.journalDailyWorkDate
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>{detail.employeeJobType.name}</TableCell>
                    <TableCell align='right'>
                      {fNumber(detail.jobQuantity)}
                    </TableCell>
                    <TableCell align='right'>
                      {fNumber(detail.jobPrice)}
                    </TableCell>
                    <TableCell align='right'>
                      {fNumber(detail.dayPayment)}
                    </TableCell>
                    <TableCell>{detail.journalDailyWork.farm.name}</TableCell>
                    <TableCell>{detail.journalDailyWork.tenant.name}</TableCell>
                    <TableCell align='right'>
                      {isDeleteAction ? (
                        <Tooltip
                          title='Remover de planilla'
                          enterTouchDelay={0}
                        >
                          <span>
                            <IconButton
                              color='error'
                              onClick={() => handleDelete(detail)}
                              disabled={isLoading}
                            >
                              <ArrowCircleRightIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title='Incluir en planilla'
                          enterTouchDelay={0}
                        >
                          <span>
                            <IconButton
                              color='success'
                              onClick={() => handleAddToPayroll(detail)}
                              disabled={isLoading}
                            >
                              <ArrowCircleLeftIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Grid container spacing={1}>
          <Grid xs={12} md={6}>
            <Typography variant='body2' fontWeight='bold' sx={{ mx: 1 }}>
              Total = {fNumber(totalPayment)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOnClose}
          color='primary'
          sx={{ mr: 2 }}
          disabled={isLoading}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PayrollEmployeeWorkDetails
