import { FC, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { Farm } from '../../types/Farm'
import { FarmJobType } from '../../types/Journal'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { constants } from '../contants'
import { ProductInventory } from '../../types/Product'
import DailyWorkProducts from './DailyWorkProducts'
import { endpoints } from '../../api/constants'
import { axiosPrivate } from '../../api/axios'
import { useAuth } from '../../context/AuthProvider'
import { AxiosError } from 'axios'
import { useSnackbar } from '../../context/SnackbarProvider'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'

interface FarmInfoProps {   
  validateFarmRequiredValues: boolean
}

const FarmInfo: FC<FarmInfoProps> = ({  
  validateFarmRequiredValues,
}) => {  
  const {
    selectedFarm,
    setSelectedFarm,
    selectedFarmJobTypes,
    setSelectedFarmJobTypes,
    selectedDate,
    setSelectedDate,
    observations,
    setObservations,
    selectedProducts,
    setSelectedProducts,
    journalDailyWorkProducts,
    setJournalDailyWorkProducts,
  } = useDailyWorkReport()
  const { auth } = useAuth()
  const { selectedTenant } = auth 
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(selectedDate === null)
  const [farms, setFarms] = useState<Farm[]>([])
  const [products, setProducts] = useState<ProductInventory[]>([])
  const { showError } = useSnackbar()
  const [farmJobTypes, setFarmJobTypes] = useState<FarmJobType[]>([])
  const minDate = dayjs().subtract(2, 'month')
  const maxDate = dayjs().add(2, 'month')

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const getFarms = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarms}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setFarms(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {
          showError('Error al cargar las fincas')
        }
      }
    }

    const getProducts = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getProducts}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setProducts(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {
          showError('Error al cargar los productos del inventario')
        }
      }
    } 

    const getFarmJobTypes = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getFarmJobTypes, { signal: controller.signal }
        )
        if (isMounted) {         
          setFarmJobTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name === 'CanceledError') {
         //console.log('The request has been canceled')
        } else {
          showError('Error al cargar los tipos de trabajo para el reporte diario')
        }
      }
    }

    getFarms()
    getProducts()
    getFarmJobTypes()

    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id])

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue)
    if (!newValue) {
      setIsDateEmpty(true)
      setDateError(null)
    } else {
      setIsDateEmpty(false)
      setDateError(null)
    }
  }
  
  const dateErrorMessage = useMemo(() => {    
    if (isDateEmpty && validateFarmRequiredValues) return constants.requiredFieldMessage

    switch (dateError) {
      case 'maxDate':
        return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
      case 'minDate':
        return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
      case 'invalidDate':
        return 'La fecha ingresada no es válida'
      default:
        return ''
    }
  }, [dateError, isDateEmpty, maxDate, minDate, validateFarmRequiredValues])

  return (
    <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
      <Grid xs={12} md={6}>
        <MobileDatePicker
          format='DD/MM/YYYY'
          label='Fecha'
          value={selectedDate}
          onChange={handleDateChange}
          sx={{ width: '100%' }}
          onError={(newError) => setDateError(newError)}
          slotProps={{
            textField: {
              error: validateFarmRequiredValues && (isDateEmpty || dateError != null),
              helperText: dateErrorMessage,
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <Autocomplete
          disablePortal
          id='combo-box-demo'
          options={farms}
          getOptionLabel={(farm) => farm.name}
          isOptionEqualToValue={(option: Farm, value: Farm) =>
            option.id === value.id
          }
          value={selectedFarm}
          onChange={(event, newValue) => {           
            setSelectedFarm(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Finca'
              error={validateFarmRequiredValues && !selectedFarm}
              helperText={(validateFarmRequiredValues && !selectedFarm) ? constants.requiredFieldMessage : ''}
            />
          )}
        />
      </Grid>      
      <Grid xs={12} md={12}>
        <Autocomplete
          multiple
          id='combo-box-demo2'
          options={farmJobTypes}
          getOptionLabel={(journalDailyWorkJobType) =>
            journalDailyWorkJobType.name
          }
          isOptionEqualToValue={(
            option: FarmJobType,
            value: FarmJobType
          ) => option.id === value.id}
          filterSelectedOptions={true}
          value={selectedFarmJobTypes}
          onChange={(event, newValue) => {
            setSelectedFarmJobTypes(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Trabajos realizados'
              error={validateFarmRequiredValues && selectedFarmJobTypes.length === 0}
              helperText={(validateFarmRequiredValues && selectedFarmJobTypes.length === 0) ? constants.requiredFieldMessage : ''}
            />
          )}
        />
      </Grid>    
      <DailyWorkProducts
      products={products}
      selectedProducts={selectedProducts}
      setSelectedProducts={setSelectedProducts} 
      journalDailyWorkProducts={journalDailyWorkProducts}
      setJournalDailyWorkProducts={setJournalDailyWorkProducts}
      validateProductsRequiredValues={validateFarmRequiredValues}
      />  
      <Grid xs={12} md={12}>
        <TextField
          sx={{ width: '100%' }}
          id='outlined-multiline-flexible'
          label='Observaciones'
          multiline
          maxRows={4}
          value={observations}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setObservations(event.target.value)
          }
        />
      </Grid>
    </Grid>
  )
}

export default FarmInfo
