import { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Dayjs } from 'dayjs'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { endpoints } from '../../api/constants'
import { axiosPrivate } from '../../api/axios'
import { AxiosError } from 'axios'
import { Tenant } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { PaymentType } from '../../types/Employee'
import { Farm } from '../../types/Farm'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { EmployeeJobType } from '../../types/Journal'

interface PayrollFiltersProps {   
  tenants: Tenant[] 
  selectedTenant: Tenant | null
  setSelectedTenant: (tenant: Tenant | null) => void
  farms: Farm[]
  selectedJobTypeIds: number[]
  setSelectedJobTypeIds: (jobTypeIds: number[]) => void
  employeeJobTypes: EmployeeJobType[]
  selectedFarm: Farm | null
  setSelectedFarm: (farm: Farm | null) => void
  selectedPaymentType: PaymentType | null
  setSelectedPaymentType: (paymentType: PaymentType | null) => void
  selectedFromDate?: Dayjs | null 
  onFromDateChange?: (date: Dayjs | null) => void
  selectedToDate?: Dayjs | null 
  onToDateChange?: (date: Dayjs | null) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const PayrollFilters: FC<PayrollFiltersProps> = ({
  tenants,
  selectedTenant,
  setSelectedTenant,
  farms,
  selectedJobTypeIds,
  setSelectedJobTypeIds,
  employeeJobTypes,
  selectedFarm,
  setSelectedFarm,
  selectedPaymentType,
  setSelectedPaymentType,
  selectedFromDate,
  onFromDateChange,
  selectedToDate,
  onToDateChange,
}) => {
  const [tempSelectedJobTypeIds, setTempSelectedJobTypeIds] = useState<number[]>(selectedJobTypeIds)
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([])  
  const { showError } = useSnackbar()
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()    

    const getPaymentTypes = async () => {
      try {
        const response = await axiosPrivate.get(
          endpoints.getPaymentTypes,
          { signal: controller.signal }
        )
        if (isMounted) {
          setPaymentTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los tipos de pago')
        }
      }
    }  
    
    getPaymentTypes() 
  
    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectedJobTypeTempChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event
    setTempSelectedJobTypeIds(
      typeof value === 'string' ? value.split(',').map(item => parseInt(item, 10)) : value,
    )
  }

  const handleSelectedJobTypeClose = () => {
    setSelectedJobTypeIds(tempSelectedJobTypeIds)
  }


  return (
    <div style={{ marginBottom: '40px' }}>
      <Accordion sx={{ borderRadius: '12px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            padding: '1',
            minHeight: '0 !important',
            '& .MuiAccordionSummary-content': { margin: '8px 0' },
          }}
        >
          <Typography
            fontWeight='bold'
            sx={{ fontSize: '0.900rem', lineHeight: '1.2' }}
          >
            Filtrar
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={0.7} sx={{ my: 0, mx: 1, p: 0 }}>
            <Grid xs={12} md={4}>
              <Autocomplete
                size='small'
                sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-perfil'
                options={tenants}
                getOptionLabel={(tenant) => tenant.name}
                isOptionEqualToValue={(option: Tenant, value: Tenant) =>
                  option.id === value.id
                }
                value={selectedTenant}
                onChange={(event, newValue) => {
                  setSelectedTenant(newValue)
                  if (newValue?.id !== selectedFarm?.tenantId) {
                    setSelectedFarm(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Perfil' />
                )}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <Autocomplete
                size='small'
                sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-finca'
                options={farms}
                getOptionLabel={(farm) => farm.name}
                isOptionEqualToValue={(option: Farm, value: Farm) =>
                  option.id === value.id
                }
                value={selectedFarm}
                onChange={(event, newValue) => {
                  setSelectedFarm(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Finca' />
                )}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <FormControl fullWidth
              sx={{ 
                minWidth: 200 ,
                ml: { xs: 0, md: 2 },
                mt: { xs: 1, md: 0 },
              }}>
                <InputLabel id='demo-multiple-checkbox-label'>
                  Trabajo
                </InputLabel>
                <Select
                  size='small'                  
                  fullWidth
                  labelId='demo-multiple-checkbox-label'
                  id='demo-multiple-checkbox'
                  multiple
                  value={tempSelectedJobTypeIds}
                  onChange={handleSelectedJobTypeTempChange}
                  onClose={handleSelectedJobTypeClose}
                  input={<OutlinedInput label='Trabajo' />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (id) =>
                          employeeJobTypes.find((job) => job.id === id)?.name
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {employeeJobTypes.map((jobType) => (
                    <MenuItem key={jobType.id} value={jobType.id}>
                      <Checkbox
                        checked={tempSelectedJobTypeIds.includes(jobType.id)}
                      />
                      <ListItemText primary={jobType.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <Autocomplete
                size='small'
                sx={{ 
                  minWidth: 200 ,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                fullWidth
                disablePortal
                id='combo-box-tipo-pago'
                options={paymentTypes}
                getOptionLabel={(payment) => payment.name}
                isOptionEqualToValue={(
                  option: PaymentType,
                  value: PaymentType
                ) => option.id === value.id}
                value={selectedPaymentType}
                onChange={(event, newValue) => {
                  setSelectedPaymentType(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Tipo de pago' />
                )}
              />
            </Grid>
            {selectedFromDate !== undefined && onFromDateChange && (
              <Grid xs={12} md={4}>
                <MobileDatePicker
                  format='DD/MM/YYYY'
                  label='Fecha desde'
                  value={selectedFromDate}
                  onChange={(newDate) => onFromDateChange(newDate)}
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                  }}
                  sx={{
                    width: '100%',
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 1, md: 0 },
                    '.MuiInputBase-root': {
                      // size='small'
                      height: '40px',
                      fontSize: '0.875rem',
                    },
                    '.MuiInputLabel-root': {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              </Grid>
            )}
            {selectedToDate !== undefined && onToDateChange && (
              <Grid xs={12} md={4}>
                <MobileDatePicker
                  format='DD/MM/YYYY'
                  label='Fecha hasta'
                  value={selectedToDate}
                  onChange={(newDate) => onToDateChange(newDate)}
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                  }}
                  sx={{
                    width: '100%',
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 1, md: 0 },
                    '.MuiInputBase-root': {
                      // size='small'
                      height: '40px',
                      fontSize: '0.875rem',
                    },
                    '.MuiInputLabel-root': {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default PayrollFilters
