import { FC, useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { ApiError, axiosPrivate } from '../../api/axios'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Iconify from '../../components/iconify'
import { Farm } from '../../types/Farm'
import {
  JournalDailyWork,
  JournalDailyWorkDetail,
  JournalDailyWorkPaymentStatus,
} from '../../types/Journal'
import TextField from '@mui/material/TextField'
import { formatDateTimeCustom, formatFullDateWithDay } from '../../utils/format-time'
import Divider from '@mui/material/Divider'
import { Employee, getFullName } from '../../types/Employee'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import DailyWorkFilters from './DailyWorkFilters'
import dayjs, { Dayjs } from 'dayjs'
import DynamicDialog from '../../components/DynamicDialog'
import DialogContentText from '@mui/material/DialogContentText'
import ReportSkeletons from './ReportSkeletons'
import { generateUniqueID } from '../../utils/common'
import { fNumber } from '../../utils/format-number'

type JournalDailyWorkRequest = {
  paymentStatus: JournalDailyWorkPaymentStatus
  tenantId: number
  farmId: number | null
  fromDate: string | null
  toDate: string | null
  employeeId: number | null
}

type MenuOption = {
  name: string
  isEnable: boolean
  action: (id: number) => void
}

const MIN_LOADING_TIME = 500
const DailyWorkReportView: FC = () => {
  const { showSuccess, showError } = useSnackbar()
  const [journalDailyWorkReports, setJournalDailyWorkReports] = useState<JournalDailyWork[]>([])
  const { auth } = useAuth()
  const { selectedTenant } = auth
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null)
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<JournalDailyWorkPaymentStatus>(
      JournalDailyWorkPaymentStatus.PendingPayment
    )
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null)
  const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(null)
  const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(null)
  const navigate = useNavigate()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deletingJournalId, setDeletingJournalId] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const reportMenuOptions: MenuOption[] = [
    {
      name: 'Editar',
      isEnable: true,
      action: (id: number) => {
        const reportToEdit = journalDailyWorkReports.find(
          (report) => report.id === id
        )
        if (reportToEdit) {
          navigate(`/reporte-trabajo/editar/${id}`, {
            state: { currentReport: reportToEdit },
          })
        }
      },
    },    
    {
      name: 'Copiar cuadrilla',
      isEnable: true,
      action: (id: number) => {
        const journalReport = journalDailyWorkReports.find(
          (report) => report.id === id
        )
        if (journalReport) {
          const journalDailyWorkDetails = getJournalDetails(journalReport)          
          navigate("/reporte-trabajo/agregar", {
            state: { initialJournalDailyWorkDetails: journalDailyWorkDetails },
          })
        }       
      },
    },
    {
      name: 'Eliminar',
      isEnable: true,
      action: (id: number) => {
        setDeletingJournalId(id)
        setOpenDeleteModal(true)
      },
    },
  ]

  const getJournalDetails = (journalReport: JournalDailyWork): JournalDailyWorkDetail[] => {
    return journalReport.journalDailyWorkDetails.map((detail) => {
      return {
        id: generateUniqueID(),
        employee: detail.employee,
        employeeJobType: {
          id: detail.employeeJobType.id,
          name: detail.employeeJobType.name,
          jobPrice: {
            id: generateUniqueID(),
            price: detail.jobPrice
          }
        },
        jobQuantity: detail.jobQuantity,
        jobPrice: detail.jobPrice,
        paid: detail.paid
      }
    })
  }

  const handleOnConfirmDelete = async () => {
    if (deletingJournalId !== null) {
      const payroll = await getPayrollForJournal(deletingJournalId)
      setOpenDeleteModal(false)
      
      if (payroll) {        
        setOpenConfirmDeleteModal(true)
      } else {        
        await DeleteJournal(deletingJournalId)        
      }
    }
  }

  const handleOnCloseConfirmDelete = () => {
    setOpenConfirmDeleteModal(false)
  }
  
  const handleOnConfirmDeleteWithPayroll = async () => {
    if (deletingJournalId !== null) {
      await DeleteJournal(deletingJournalId)
      setOpenConfirmDeleteModal(false)
      setOpenDeleteModal(false)
    }
  }

  const dialogConfirmDeleteWithPayrollAcceptButton = {
    text: 'Eliminar de todas formas',
    action: handleOnConfirmDeleteWithPayroll,
  }
  
  const dialogConfirmDeleteWithPayrollCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseConfirmDelete,
  }

  const handleOnCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  const dialogAcceptButton = {
    text: 'Eliminar',
    action: handleOnConfirmDelete,
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseDelete,
  }

  const handleClickReportMenu = (
    event: React.MouseEvent<HTMLElement>,
    reportId: number
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedReportId(reportId)
  }

  const handleCloseReportMenu = () => {
    setAnchorEl(null)
  }

  const journalDailyWorkRequest: JournalDailyWorkRequest = {
    paymentStatus: selectedPaymentStatus,
    tenantId: selectedTenant?.id!,
    farmId: selectedFarm ? selectedFarm.id : null,
    fromDate: selectedFromDate ? selectedFromDate.format('YYYY-MM-DD') : null,
    toDate: selectedToDate ? selectedToDate.format('YYYY-MM-DD') : null,
    employeeId: selectedEmployee ? selectedEmployee.id : null,
  }

  const getJournalDailyWorkReports = async (controller: AbortController) => {
    setIsLoading(true)
    try {
      const fetchStart = Date.now()     

      const responsePromise = axiosPrivate.post(
        endpoints.getJournalDailyWorks,
        journalDailyWorkRequest,
        { signal: controller.signal }
      )

      const [response] = await Promise.all([
        responsePromise,
        new Promise((resolve) =>
          setTimeout(resolve, MIN_LOADING_TIME - (Date.now() - fetchStart))
        ), // Ensure a minimum loading time
      ])

      setJournalDailyWorkReports(response.data)
      setIsLoading(false)      
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al obtener los reportes de trabajo')
      }
    }   
  }    

  const getPayrollForJournal = async (journalDailyWorkId: number) => {
    const controller = new AbortController()
    setIsLoading(true)
    try {       
      const response = await axiosPrivate.get(
        `${endpoints.getPayrollMasterForJournalDailyWork}${journalDailyWorkId}`, 
        { signal: controller.signal }
      )
      setIsLoading(false)
      const payroll = response.data
      return payroll 
    } catch (err: any) {
      const error = err as AxiosError<ApiError>
      if (error.name !== 'CanceledError') {
        showError('Error consultar la planilla ' + error?.response?.data?.detail)
      }
      setIsLoading(false)
      return null
    }
  }   

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getJournalDailyWorkReports(controller)
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTenant?.id,
    selectedPaymentStatus,
    selectedFromDate,
    selectedToDate,
    selectedFarm,
    selectedEmployee,
  ])

  let lastColor = '#FDF7E4'
  let lastDate = ''
  const getNextColor = (currentDate: string) => {
    if (currentDate !== lastDate) {
      lastColor = lastColor === '#D6E6F2' ? '#FDF7E4' : '#D6E6F2'
      lastDate = currentDate
    }
    return lastColor
  }

  const DeleteJournal = async (journalId: number) => {
    const controller = new AbortController()

    const SendDeleteJournalRequest = async () => {
      try {
        const url = endpoints.deleteJournalDailyWork
          .replace('{journalDailyWorkId}', journalId.toString())
          .replace('{deletedByUserEmail}', auth.user?.email || '')

        const response = await axiosPrivate.delete(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Reporte eliminado correctamente!')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name === 'CanceledError') {
          //console.log('The request has been canceled')
        } else {
          showError(
            'Error al eliminar el reporte ' + error?.response?.data?.detail
          )
        }
      } finally {
        getJournalDailyWorkReports(controller)
      }
    }

    SendDeleteJournalRequest()
  }

  const isModifiedDateGreater = (createdDate: string, modifiedDate: string) => {
    return dayjs(modifiedDate).diff(dayjs(createdDate), 'second') >= 10
  }
 
  return (
    <Container>
      <Stack
        sx={{ mb: 1 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid xs={12} md={8}>
            <Typography variant='h4'>Reporte de Trabajo</Typography>
          </Grid>

          <Grid
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              component={NavLink}
              to='/reporte-trabajo/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <DailyWorkFilters
        selectedPaymentStatus={selectedPaymentStatus}
        onPaymentStatusChange={setSelectedPaymentStatus}
        selectedFarm={selectedFarm}
        setSelectedFarm={setSelectedFarm}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
        selectedFromDate={selectedFromDate}
        setSelectedFromDate={setSelectedFromDate}
        selectedToDate={selectedToDate}
        setSelectedToDate={setSelectedToDate}
      />

      <Card>
        <CardContent>
          {isLoading ? (
            <ReportSkeletons count={5} />
          ) : (
            journalDailyWorkReports.map((report, index) => {
              const optionsWithPermissions = reportMenuOptions.map((option) => {
                if (option.name === 'Editar') {
                  return { ...option, isEnable: report.canEdit }
                }
                if (option.name === 'Eliminar') {
                  return { ...option, isEnable: report.canDelete }
                }
                return option
              })
              return (
                <Grid xs={12} container spacing={1} sx={{ ml: 1 }} key={index}>
                  <Grid
                    sx={{ mb: 0, pb: 0 }}
                    container
                    alignItems='center'
                    justifyContent='space-between'
                    xs={12}
                    md={6}
                  >
                    <Grid
                      sx={{
                        bgcolor: getNextColor(
                          dayjs(report.journalDailyWorkDate).format(
                            'YYYY-MM-DD'
                          )
                        ),
                        borderRadius: '8px',
                        mb: 0,
                        mt: 0,
                        pb: 0.5,
                        pt: 0.5,
                      }}
                      xs={11}
                      md={12}
                    >
                      <Typography fontWeight='bold' sx={{ ml: 2, mt: 0.5 }}>
                        Fecha:{' '}
                        {formatFullDateWithDay(report.journalDailyWorkDate)}
                      </Typography>
                    </Grid>
                    <Grid xs={1}>
                      <IconButton
                        aria-label='more'
                        id='long-button'
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={(e) => handleClickReportMenu(e, report.id)}
                        size='small'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='space-between'
                    xs={12}
                    md={6}
                  >
                    <Grid xs={12} md={11} sx={{ mb: { xs: -1, md: 1 } }}>
                      <TextField
                        size='small'
                        label='Finca'
                        value={report.farm.name}
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={1}>
                      <IconButton
                        aria-label='more'
                        id='long-button'
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={(e) => handleClickReportMenu(e, report.id)}
                        size='small'
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseReportMenu}
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                  >
                    {optionsWithPermissions.map((option) => (
                      <MenuItem
                        key={option.name}
                        onClick={() => {
                          handleCloseReportMenu()
                          if (selectedReportId !== null && option.isEnable) {
                            option.action(selectedReportId)
                          }
                        }}
                        disabled={!option.isEnable}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Menu>

                  <Grid xs={12} md={12}>
                    <TextField
                      size='small'
                      label='Trabajos realizados'
                      value={report.journalDailyWorkFarmJobTypes
                        .map((jt) => jt.farmJobType.name)
                        .join(', ')}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      multiline
                      maxRows={3}
                    />
                  </Grid>

                  {report.journalDailyWorkProducts.length > 0 && (
                    <Grid xs={12} md={12}>
                      <TextField
                        size='small'
                        label='Productos utilizados'
                        value={report.journalDailyWorkProducts
                          .map(
                            (pd) =>
                              `${pd.productInventory.name} ${pd.productQuantity} ${pd.productInventory.productMeasure.name}`
                          )
                          .join(', ')}
                        InputProps={{ readOnly: true }}
                        fullWidth
                        multiline
                        maxRows={3}
                      />
                    </Grid>
                  )}

                  {report.description && (
                    <Grid xs={12} md={12}>
                      <TextField
                        size='small'
                        label='Observaciones'
                        value={report.description}
                        InputProps={{ readOnly: true }}
                        fullWidth
                        multiline
                        maxRows={3}
                      />
                    </Grid>
                  )}

                  {report.journalDailyWorkDetails.map((detail, detailIndex) => (
                    <Grid
                      container
                      xs={12}
                      spacing={0}
                      sx={{ ml: 1 }}
                      key={detailIndex}
                    >
                      <Grid xs={12} md={3}>
                        <Typography
                          fontWeight='bold'
                          sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                        >
                          {getFullName(detail.employee)}
                        </Typography>
                      </Grid>
                      <Grid xs={7} md={3}>
                        <Typography
                          sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                        >
                          {detail.employeeJobType.name}
                        </Typography>
                      </Grid>
                      <Grid xs={2} md={1}>
                        <Typography
                          sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                        >
                          {fNumber(detail.jobQuantity)}
                        </Typography>
                      </Grid>
                      <Grid xs={3} md={2}>
                        <Typography
                          sx={detail.paid ? { backgroundColor: '#e6ffe6' } : {}}
                        >
                          x {fNumber(detail.jobPrice)}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 1 }}>
                    <Divider style={{ width: '100%' }} />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <Typography variant='body2' sx={{ mx: 1 }}>
                      Reportado: {formatDateTimeCustom(report.createdDate)} -{' '}
                      {report.createdByUserEmail}
                    </Typography>
                  </Grid>

                  {isModifiedDateGreater(
                    report.createdDate,
                    report.modifiedDate
                  ) && (
                    <Grid xs={12} md={6}>
                      <Typography variant='body2' sx={{ mx: 1 }}>
                        Actualizado: {formatDateTimeCustom(report.modifiedDate)}{' '}
                        - {report.modifiedByUserEmail}
                      </Typography>
                    </Grid>
                  )}

                  <Grid xs={12} container sx={{ mx: 1, mt: 1, mb: 2 }}>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              )
            })
          )}
          <DynamicDialog
            openDialog={openDeleteModal}
            dialogTitle='Confirmar eliminación'
            onCloseAction={handleOnCloseDelete}
            buttonAccept={dialogAcceptButton}
            buttonCancel={dialogCancelButton}
          >
            <DialogContentText id='generic-dialog-description'>
              ¿Realmente desea eliminar el reporte de trabajo?
            </DialogContentText>
          </DynamicDialog>
          <DynamicDialog
            openDialog={openConfirmDeleteModal}
            dialogTitle='Confirmar eliminación con planilla asociada'
            onCloseAction={handleOnCloseConfirmDelete}
            buttonAccept={dialogConfirmDeleteWithPayrollAcceptButton}
            buttonCancel={dialogConfirmDeleteWithPayrollCancelButton}
          >
            <DialogContentText id='confirm-delete-with-payroll-description'>
              Este reporte está asociado a una planilla existente. ¿Aún así desea
              continuar con la eliminación?
            </DialogContentText>
          </DynamicDialog>
        </CardContent>
      </Card>
    </Container>
  )
}

export default DailyWorkReportView