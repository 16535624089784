import { FC, useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import { ProductInventory } from '../../types/Product'
import { endpoints } from '../../api/constants'
import { AxiosError } from 'axios'
import { ApiError, axiosPrivate } from '../../api/axios'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
    DataGrid,
    GridColDef,
    GridRowId,
    GridActionsCellItem,
    GridRowParams,
    GridValueGetterParams,
  } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import DataGridToolbar from '../../components/datagridtoolbar'
import Button from '@mui/material/Button'
import Iconify from '../../components/iconify'
import { NavLink, useNavigate } from 'react-router-dom'
import DialogContentText from '@mui/material/DialogContentText'
import DynamicDialog from '../../components/DynamicDialog'
import ProductSkeletons from './ProductSkeletons'
import { fNumber } from '../../utils/format-number'

const ProductInventoryView: FC = () => {
    const [products, setProducts] = useState<ProductInventory[]>([])
    const [filteredProducts, setFilteredProducts] = useState<ProductInventory[]>([])
    const { auth } = useAuth()
    const { selectedTenant } = auth
    const { showSuccess, showError } = useSnackbar()
    const [searchText, setSearchText] = useState('')
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [deletingProductId, setDeletingProductId] = useState<number | null>(null)

    useEffect(() => {
        setFilteredProducts(products)
    }, 
    [products])

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
    
        if (isMounted) {
          getProducts()
        }
    
        return () => {
          isMounted = false
          controller.abort()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant?.id])

        const getProducts = async () => {
            try {
              setIsLoading(true)
              const response = await axiosPrivate.get(
                `${endpoints.getProducts}${selectedTenant?.id}`
              )
              setProducts(response.data)
              setIsLoading(false)
            } catch (err: any) {
              const error = err as AxiosError
              if (error.name === 'CanceledError') {
                // console.log('The request has been canceled')
              } else {
                setIsLoading(false)
                showError('Error al cargar los productos del inventario')
              }
            }
        }

        const handleEditRow = (id: GridRowId) => () => {
            const productToEdit = products.find(product => product.id === id)
            navigate(`/inventario-productos/editar/${id}`, { state: { currentProduct: productToEdit } })
        }

        const handleDeleteRow = (id: GridRowId) => () => {           
            setDeletingProductId(Number(id))
            setOpenDialog(true)      
        }

        const handleOnCloseDelete = () => {
            setOpenDialog(false)
        }
        
        const handleOnConfirmDelete = async () => {
            if (deletingProductId !== null) {
                await DeleteProduct(Number(deletingProductId))
                setOpenDialog(false)
            }
        }

        const dialogAcceptButton = {
          text: 'Eliminar',
          action: handleOnConfirmDelete,
        }
      
        const dialogCancelButton = {
          text: 'Cancelar',
          action: handleOnCloseDelete,
        }

        const DeleteProduct = async (productId: number) => {
            setIsLoading(true)
            const controller = new AbortController()
        
            const SendDeleteProductRequest = async () => {    
                try {
                    const url = endpoints.deleteProduct
                    .replace('{productId}', productId.toString())
                    .replace('{deletedByUserEmail}', auth.user?.email || '')

                  const response = await axiosPrivate.delete(url,{ signal: controller.signal })         
            
                  if (response.status === 200) {
                    showSuccess('Producto eliminado correctamente!')            
                    navigate('/inventario-productos')            
                  }
                } catch (err: any) {
                  const error = err as AxiosError<ApiError>
            
                  if (error.name === 'CanceledError') {
                    //console.log('The request has been canceled')
                  } else {
                    showError('Error eliminar el producto ' + error?.response?.data?.detail)
                  }
                } finally {
                  setIsLoading(false)
                  getProducts()                 
                }
              }
        
              SendDeleteProductRequest()
          }

        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value)
            if (event.target.value === "") {
                setFilteredProducts(products)
            } else {
              const lowercasedFilter = event.target.value.toLowerCase()
              const filteredData = products.filter((product) => {
                return (
                  product.name.toLowerCase().includes(lowercasedFilter)
                )
              })
              setFilteredProducts(filteredData)
            }
          }

        const columns: GridColDef[] = [
          {
            headerName: 'Producto',
            field: 'product',
            valueGetter: (params: GridValueGetterParams<ProductInventory>) =>
              params.row.name,
            minWidth: 140,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),
          },
          {
            headerName: 'Cantidad',
            field: 'quantity',
            minWidth: 128,
            flex: 1,
            valueGetter: (params) => fNumber(params.value),
          },
          {
            headerName: 'Medida',
            field: 'productMeasure',
            valueGetter: (params: GridValueGetterParams<ProductInventory>) =>
              params.row.productMeasure?.name,
            minWidth: 140,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),
          },
          {
            headerName: 'Precio',
            field: 'unitPrice',
            minWidth: 128,
            flex: 1,
            valueGetter: (params) => fNumber(params.value),
          },
          {
            headerName: 'Descripción',
            field: 'description',
            valueGetter: (params: GridValueGetterParams<ProductInventory>) =>
              params.row.description,
            minWidth: 150,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),
          },
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 55,
            getActions: (params: GridRowParams<ProductInventory>) => [
              <GridActionsCellItem
                sx={{ p: 0, m: 0 }}
                icon={<EditIcon />}
                label='Editar'
                className='textPrimary'
                onClick={handleEditRow(params.row.id)}
                color='inherit'
              />,
              <GridActionsCellItem
                sx={{ p: 0, m: 0 }}
                icon={<DeleteIcon />}
                label='Eliminar'
                onClick={handleDeleteRow(params.row.id)}
                color='inherit'
              />,
            ],
          },
        ]        

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid xs={12} md={8}>
            <Typography variant='h4'>Inventario de productos</Typography>
          </Grid>

          <Grid
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              component={NavLink}
              to='/inventario-productos/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar Producto
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Card>
        <CardContent sx={{ pt: 0 }}>
          <Grid container spacing={3}>
          {isLoading ? (
            <ProductSkeletons count={5} />
          ) :
            (<Grid xs={12} md={12}>
              <DataGrid
                rows={filteredProducts}
                columns={columns}
                disableRowSelectionOnClick
                density='compact'
                slots={{
                  toolbar: DataGridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    filterSearchText: searchText,
                    onFilterSearchText: handleSearchChange,
                  },
                }}
              />
            </Grid>)}
          </Grid>
        </CardContent>
      </Card>
      <DynamicDialog
            openDialog={openDialog}
            dialogTitle='Confirmar eliminación'
            onCloseAction={handleOnCloseDelete}
            buttonAccept={dialogAcceptButton}
            buttonCancel={dialogCancelButton}
          >
            <DialogContentText id='generic-dialog-description'>
              ¿Realmente desea eliminar este producto?
            </DialogContentText>
          </DynamicDialog>

    </Container>
  )
}

export default ProductInventoryView
  